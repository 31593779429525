import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { useQueryParam, StringParam } from 'use-query-params'
import { SectionHeader, Section } from '../styles/styles'

import Layout from '../components/layout'
import SEO from '../components/seo'

const BeerGrid = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const BeerTitle = styled.h3`
  margin-bottom: 10px;
`
const BeerLogo = styled.div`
  flex: 1;
  display: flex;
  width: 80%;
`
const BeerDescription = styled.p`
  color: #b4b4b4;
  font-size: 0.9rem;
`
const BeerCard = styled.div`
  background-color: rgba(27, 28, 29, 0.95);
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
`
const BeerDataContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`
const BeerDataItem = styled.div`
  font-family: 'Roboto Condensed';
  font-weight: bold;
  font-size: 1.35rem;
  color: #a3a569;
`

const CurrentBeers = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

const AboutBeer = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`

const BeerHeading = styled.h1`
  flex: 1;
  white-space: nowrap;
  @media (max-width: 480px) {
    white-space: normal;
  }
`
const Filters = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 1.75rem;
  margin-left: 80px;
  flex: 10;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 15px;
    margin-left: 20px;
  }
`
const Filter = styled.a`
  margin-right: 50px;
  cursor: pointer;
  color: #a3a569;
  text-transform: uppercase;
  padding-bottom: 5px;
  font-family: 'Roboto Condensed';
  font-weight: bold;
  font-size: 1.1rem;
  &::after {
    display: block;
    content: '';
    background: transparent;
    height: 2px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
  }

  &.active {
    color: #fff;
    &::after {
      display: block;
      content: '';
      background: #a3a569;
      height: 2px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  @media (max-width: 480px) {
    margin-bottom: 5px;
    &.active {
      color: #fff;
      &::after {
        display: block;
        content: '';
        background: #a3a569;
        height: 2px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
`

const Beers = ({ beers, aboutText }) => (
  <BeerGrid>
    <AboutBeer>
      <BeerDescription
        dangerouslySetInnerHTML={{
          __html: aboutText.replace(/\n/g, '<br />')
        }}
      ></BeerDescription>
    </AboutBeer>
    {beers.map(beer => (
      <BeerCard key={beer.id}>
        <BeerLogo>
          <Img
            style={{ flex: 1, height: 200 }}
            imgStyle={{ objectFit: 'contain' }}
            // src={beer.logo.file.url}
            fluid={beer.logo.fluid}
          />
        </BeerLogo>
        <h2 style={{ marginTop: 20, marginBottom: 20 }}>{beer.title}</h2>
        <BeerDescription style={{ flex: 1 }}>
          {beer.longDescription
            ? beer.longDescription.content[0].content[0].value
            : beer.description}
        </BeerDescription>
        <BeerDataContainer>
          {beer.srm && <BeerDataItem>SRM {beer.srm}</BeerDataItem>}
          {beer.ibu && <BeerDataItem>IBU {beer.ibu}</BeerDataItem>}
          {beer.abv && <BeerDataItem>ABV {beer.abv}%</BeerDataItem>}
        </BeerDataContainer>
      </BeerCard>
    ))}
  </BeerGrid>
)

const SecondPage = ({ data }) => {
  const [section, setSection] = useQueryParam('section', StringParam)
  const [filter, setFilter] = useState(section || 'beer')

  const map = {
    beer: 'Beers',
    limited: 'Limited Release',
    ciders: 'Ciders & Meads'
  }

  return (
    <Layout bgImage={data.contentfulBeerPage.backgroundImage}>
      <SEO title={data.contentfulBeerPage.pageTitle} />
      <BeerHeading>{data.contentfulBeerPage.pageTitle}</BeerHeading>
      <SectionHeader>Current Beers</SectionHeader>
      <Section>
        <CurrentBeers>
          {data.contentfulBeerPage.currentBeers
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(beer => (
              <div>
                <h3 style={{ color: '#fff', fontSize: '1.5rem' }}>
                  {beer.title}
                </h3>
                <div>{`${beer.kind ? `${beer.kind},` : ``} 
                ${beer.ibu ? `IBU: ${beer.ibu},` : ``} ${
                  beer.abv ? `ABV: ${beer.abv}%` : ``
                }
              `}</div>
              </div>
            ))}
        </CurrentBeers>
      </Section>
      <div
        style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }}
      >
        <SectionHeader>About Our Beer</SectionHeader>
        <div
          style={{
            display: 'flex',
            flexBasis: '400px',
            flexGrow: 1,
            marginTop: 10
          }}
        >
          <Filters>
            <Filter
              className={filter === 'beer' ? 'active' : ''}
              onClick={() => setFilter('beer')}
            >
              Year-rounds
            </Filter>
            <Filter
              className={filter === 'limited' ? 'active' : ''}
              onClick={() => setFilter('limited')}
            >
              Limited Release
            </Filter>
            <Filter
              className={filter === 'ciders' ? 'active' : ''}
              onClick={() => setFilter('ciders')}
            >
              Ciders &amp; Meads
            </Filter>
          </Filters>
        </div>
      </div>
      <Beers
        filter={filter}
        setFilter={setFilter}
        section={section}
        aboutText={data.contentfulBeerPage.aboutText.aboutText}
        beers={data.contentfulBeerPage.beers
          .filter(b => b.category === map[filter])
          .sort((a, b) => a.title.localeCompare(b.title))}
      ></Beers>
    </Layout>
  )
}

export default SecondPage

export const query = graphql`
  query BeerQuery {
    contentfulBeerPage {
      aboutText {
        aboutText
      }
      currentBeers {
        id
        title
        kind
        ibu
        srm
        abv
      }
      backgroundImage {
        file {
          url
        }
        fluid(maxWidth: 1024) {
          src
          srcSet
          sizes
          srcSetWebp
          srcWebp
          base64
          aspectRatio
        }
      }
      pageTitle
      beers {
        id
        title
        ibu
        srm
        abv
        category
        longDescription {
          content {
            content {
              value
            }
          }
        }
        logo {
          fluid(maxWidth: 1000) {
            src
            srcSet
            sizes
            aspectRatio
            srcSetWebp
            srcWebp
            base64
          }
          file {
            url
          }
        }
      }
    }
  }
`
